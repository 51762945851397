import React, { useState, useEffect } from "react";
import {Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'
import ScrollToTop from "react-scroll-to-top";
import Navbar from "../inc/Navbar";
import { Typewriter } from 'react-simple-typewriter' 
import HappyCustomerbiswasfurniture from "../images/happycustomer.jpg";
function Happy (){
    const [ setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage] = useState(21);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [happy, setHappy] = useState([])
      const navigate = useNavigate()
  
      const happyCollectionRef = collection(db, "happy");
      useEffect(() => {
  
          const getHappy = async () => {
              const data = await getDocs(happyCollectionRef);
              setHappy(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getHappy()
      }, [])
    return(
        <>
        <Navbar/>
        <br/>
        <br/>
        <br/>
       
        <ScrollToTop smooth top="100" color="purple" />
        <img src={HappyCustomerbiswasfurniture} class="img-fluid" alt="Our_Happy_Customer"/>
        <br/>
        <br/>
        <div className="album py-2">
        
        <div className="container">

            <div className="row">
<nav aria-label="breadcrumb">
<ol className="breadcrumb">
<li className="breadcrumb-item"><Link to="/" >Home</Link></li>
<li className="breadcrumb-item"><Link to="/washer" >Washing Mashine</Link></li>
<li className="breadcrumb-item"><Link to="/ac" >AC</Link></li>
<li className="breadcrumb-item"><Link to="/led" >Led TV</Link></li>
<li className="breadcrumb-item"><Link to="/fridge" >Refrigerator</Link></li>
<li className="breadcrumb-item"><Link to="/support" >Services & Support</Link></li>
<li className="breadcrumb-item"><Link to="/others-product" >Others</Link></li>
<li className="breadcrumb-item active" aria-current="page"> Our Happy Customer</li>
</ol>
</nav>
</div>
</div>
</div>
    <center>    
        <div className="sticky-top">
        < h1 className="card-text " style={{ margin: "auto 0", color: "Darkblue",  fontSize: "2rem"}}> <b> Our <span style={{ fontWeight: "bold", marginleft: '10px',color: "Darkblue", fontSize: "2rem"}}>
    <Typewriter 
    loop
    cursor
    cursorStyle="_"
    typeSpeed={100}
    deleteSpeed={50}
    delaySpeed={1000}
    words={[ "Happy Customer", "Happy Customer"]}
    />
</span>
</b></h1>
</div>
</center>
<br/>
<br/>

<div className="album py-4">
        
        <div className="container">

            <div className="row">
            {happy.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        val.dis.toLowerCase().includes(searchTerm.toLowerCase()) 
       
       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((website) => { return (
       
            <div className="col-md-6" >
                        <div className="card mb-4 border-White box shadow">
                        <img  className="card-img-top" src={website.Img}   alt="Happy Customer Biswas Furniture" />
                            <div className="card-body">
                                
                                <h6 className="card-text"> <b>{website.title}</b></h6>
                                <p className="card-text">{website.dis}</p>
                               

   
                    
                                <div className="d-flex justify-content-between align-items-center">
                              
                               
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                   
                            </div>
                            </div>
        </>
    );
}
export default Happy;