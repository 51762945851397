import React from "react";
import Navbar from "../inc/Navbar";

import {Link} from "react-router-dom";
import Slider from './../inc/Slider';
import Biswasfurnitureimg from "../images/fcard1.jpg";
import Biswasfurnitureimg2 from "../images/fcard2.jpg";
import Biswasfurnitureimg3 from "../images/fcard3.jpg";
import Biswasfurnitureimg4 from "../images/other.jpg";
import Brandkarimpur from "../images/BRAND2.jpg";
import Biswasfurnitureimgdurgamaa from "../images/furnitureh.jpg";
import Led from "../images/ledtv.jpg";
import Allproduct from "../pages/Allproduct";
import Mapg from "../pages/Mapg";
import ReactWhatsapp from "react-whatsapp";
import Biswasfurniturekarimpurbrand from "../images/brand.jpg";
import Videoproduct from "../pages/Videoproduct";
import './Home.css'
function Home(props){
    return(
    <>
   
    <Navbar/>
    <br/>

    
    <div className="album py-2">
        
        <div className="container">

            <div className="row">
                <h5>Best Furniture in Karimpur</h5>
                </div>
                </div>
                </div>
    <div className="p-4 mb-0 bg-dark text-light">
        
        <a href="tel: +91 8972818182" className="btn btn-info"> <small>আপনার পছন্দের ফ্রিজ, এসি, টিভি, চেয়ার ও টেবিল কিনতে ফোন করুন  +91 8972818182</small></a>
        <br/>
       
    </div>
        <Slider/>
        <div class="p-2 mb-2 bg-light text-dark">
            <br/>
        <center>
            <h5 style={{color: "purple", fontSize: "2rem"}}> <b> সহজ কিস্তিতে পাওয়া যায়। Easy EMI Available  </b></h5>
            <h2 style={{color: "Darkblue"}}>Credit & Debit Card  Available</h2>
        </center>
        <br/>
        </div>

        <div style={{color: "Green"}} class="fixed-bottom "> <ReactWhatsapp number="+91  8972818182" class="btn btn-success" message="I am Interested"> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"  fill="currentColor" class="bi bi-whatsapp"  viewBox="0 0 16 16">
  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
</svg> Whatsapp  <div className="spinner-grow text-light spinner-grow-sm"  role="status">
              <span className="visually-hidden"> Loading...</span>
            </div></ReactWhatsapp></div>

        <div class="p-0 mb-0 bg-light text-dark">
        
        <center>
            <h1 style={{color: "Darkblue"}}> <b>Best Product</b></h1>
        </center>
        
        </div>
      
        <div className="album py-2">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-4 mt-8" >
                        <div className="card mb-4 border-White box shadow">
                            <Link to="/ac">
                        <img src={Biswasfurnitureimg } className="card-img-top"   alt="Biswasfurnitureimg " />
                        </Link>
                            <div className="card-body">
                                
                                <h5  style={{color: "Secondary"}} className="card-text"> <center> Smart Inverter Air Conditioner</center></h5><br/>
                                <p className="card-text"><small> <center>আরও এসি দেখতে ক্লিক করুন</center></small></p>
                                <hr/>
                                <center>
                                    <Link to="/ac">
                                <button type="button" class="btn btn-outline-secondary">More</button>
                                </Link>
                                </center>
                               <div className="d-flex justify-content-between align-items-center">
                               
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border-white box shadow">
                           <Link to="washer">
                        <img src={Biswasfurnitureimg2 } className="card-img-top"   alt="Biswasfurnitureimg " />
                        </Link>
                            <div className="card-body">
                                <h5 className="card-text"><center> Washing Machine</center></h5><br/>
                                <p className="card-text"> <small> <center>আরও ওয়াশিং মেশিন দেখতে ক্লিক করুন</center></small></p>
                                <hr/>
                        <center>
                            <Link to="/washer">
                                <button type="button" class="btn btn-outline-secondary">More</button>
                                </Link>
                                </center>

<div className="d-flex justify-content-between align-items-center">
                                
                                        
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            {/* style={{textAlign: "center"}} */}
                            <div className="col-md-4" >
                        <div className="card mb-4 border-white box shadow">
                        <Link to="/fridge">
                        <img src={Biswasfurnitureimg3 } className="card-img-top"   alt="Biswasfurnitureimg " /></Link>
                            <div className="card-body">
                                <h5  className="card-text"><center>Smart Refrigerator</center></h5><br/>
                                <p   className="card-text"><small> <center>আরও ফ্রিজ দেখতে ক্লিক করুন</center></small></p>
                              
                                <hr/>
                                <center>
                                    <Link to="/fridge">
                                <button type="button" class="btn btn-outline-secondary">More</button>
                                </Link>
                                </center>
                          
                                <div className="d-flex justify-content-between align-items-center">
                                   
                               
                               
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border-white box shadow">
                           <Link to="/furniture">
                        <img src={Biswasfurnitureimgdurgamaa } className="card-img-top"   alt="Biswasfurnitureimage " />
                        </Link>
                            <div className="card-body">
                                <h5 className="card-text"><center>Furniture</center></h5><br/>
                                <p className="card-text"> <small> <center>আরও ফার্নিচার দেখতে ক্লিক করুন</center></small></p>
                                <hr/>
                        <center>
                            <Link to="/furniture">
                                <button type="button" class="btn btn-outline-secondary">More</button></Link>
                                </center>

<div className="d-flex justify-content-between align-items-center">
                                
                                        
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border-white box shadow">
                           <Link to="/led">
                        <img src={Led } className="card-img-top"   alt="Biswasfurnitureimg " />
                        </Link>
                            <div className="card-body">
                                <h5 className="card-text"><center>Led TV</center></h5><br/>
                                <p className="card-text"> <small> <center>আরও এল. ই. ডি. দেখতে ক্লিক করুন</center></small></p>
                                <hr/>
                        <center>
                                <button type="button" class="btn btn-outline-secondary">More</button>
                                </center>

<div className="d-flex justify-content-between align-items-center">
                                
                                        
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border-white box shadow">
                           <Link to="/others-product">
                        <img src={Biswasfurnitureimg4 } className="card-img-top"   alt="Biswasfurnitureimg " /></Link>
                            <div className="card-body">
                                <h5 className="card-text"><center>Others</center></h5><br/>
                                <p className="card-text"> <small> <center>আরও প্রোডাক্ট দেখতে ক্লিক করুন</center></small></p>
                                <hr/>
                        <center>
                            <Link to="/others-product">
                                <button type="button" class="btn btn-outline-secondary">More</button></Link>
                                </center>
<div className="d-flex justify-content-between align-items-center">
                                
                                        
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
                            <Videoproduct/>
                            <div class="p-2 mb-2 bg-dark text-light">
                                <center>
                              <h1> <b> Our Brand</b></h1>
                              </center>
                            </div>
                            <div className="first">
                            <img src={Biswasfurniturekarimpurbrand} class="img-fluid"  alt="Brand"/>
                            <img src={Brandkarimpur} class="img-fluid"  alt="Brand-karimpur"/>
                            </div>
                            <hr/>

<Allproduct/>
<br/>
<br/>
<div class="p-2 mb-2 bg-light text-dark">

                            <div className="album py-4">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-4 mt-8" >
                       
                            <div className="card-body">
                                
                                <h1  style={{color: "Gray", fontSize: "5rem" }} className="card-text"> <center> <b>40+</b></center></h1><br/>
                                <h5 style={{color: "Darkblue"}} c className="card-text"><center> আমাদের ইন্টারন্যাশনাল ব্র্যান্ড </center></h5>
                                
                               <div className="d-flex justify-content-between align-items-center">
                               
                              
                         
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                       
                            <div className="card-body">
                                <h1  style={{color: "Gray", fontSize: "5rem"}} className="card-text"><center><b>1500+</b></center></h1><br/>
                                <h5 style={{color: "Darkblue"}} c className="card-text">  <center>আমাদের প্রোডাক্ট</center></h5>
                                

<div className="d-flex justify-content-between align-items-center">
                                
                                        
                         
                            </div>
                            </div>
                               
                            </div>
                            {/* style={{textAlign: "center"}} */}
                            <div className="col-md-4" >
                      
                      
                            <div className="card-body">
                                <h1  style={{color: "Gray", fontSize: "5rem"}}  className="card-text"><center><b>1100+</b></center></h1><br/>
                                <h5  style={{color: "Darkblue"}} className="card-text"> <center> আমাদের হ্যাপি কাস্টোমার </center></h5>
                              
                            
                          
                                <div className="d-flex justify-content-between align-items-center">
                                   
                           
                    
                            </div>
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            <br/>
                            
                            <Mapg/>
                            <hr/>

    </>
    );
}

export default Home;